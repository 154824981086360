import React, {
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    OverlayTrigger,
    Table,
    Tooltip
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../components/Helmet";
import Loading from "../../components/Loading";
import Page404 from "../Page404";
import usePermissions from "../../hooks/usePermissions";

function Users() {
    const permissions = usePermissions();

    const [users, setUsers] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        setUsers(null);
        setError(null);
        axios.get("/getCompanyUsers")
            .then((response) => {
                if(response.data.valid) {
                    setUsers(response.data.users);
                } else {
                    setError("Er is een fout opgetreden. Probeer het later opnieuw. (" + response.data.error + ")")
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setError("Er is een fout opgetreden. Probeer het later opnieuw.")
            })
    }, []);

    if(!permissions.manageCompanyUsers) {
        return (
            <Page404/>
        )
    }

    return (
        <React.Fragment>
            <Helmet title="Gebruikers"/>
            <Title
                preTitle="Overzicht"
                noBottom={ !!users }
                preChildren={
                    <div className="float-right pt-2">
                        <OverlayTrigger overlay={
                            <Tooltip id={`tooltip-add-installation`}>
                                Gebruiker aanmaken
                            </Tooltip>
                        }>
                            <Link to="/users/add" className="btn btn-primary">
                                <i className="fa-solid fa-plus fa-fw"/>
                            </Link>
                        </OverlayTrigger>
                    </div>
                }
            >
                Gebruikers
            </Title>
            { error ? (
                <Alert variant="danger">{ error }</Alert>
            ) : !users ? (
                <Loading/>
            ) : (
                <Table hover>
                    <thead>
                    <tr>
                        <th>Naam</th>
                        <th>Bedrijven</th>
                    </tr>
                    </thead>
                    <tbody>
                    { users.map((user) => {
                        const url = `/user/${user.id}`;
                        return (
                            <tr key={ user.id } className="table-clickable-row">
                                <td className="align-middle">
                                    <Link to={ url }>
                                        <b>{ user.name }</b>
                                    </Link>
                                </td>
                                <td className="align-middle">
                                    <Link to={ url }>
                                        <div className="d-flex flex-column">
                                            { user.companies.map((company) => (
                                                <div className="d-flex flex-row align-items-center" key={ company.id }>
                                                    <div className="w-50 pr-3">
                                                        { company.name }
                                                    </div>
                                                    <div className="text-muted">
                                                        { company.role?.name }
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </Link>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </Table>
            )}
        </React.Fragment>
    )
}

export default React.memo(Users);
